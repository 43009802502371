import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Container } from 'react-bootstrap'

const siteTitle = 'Ecommerce Pickup and Delivery Service - Thank You'
const siteDescription =
  'Websites as a Service - The answer to high-quality business websites on a budget'

function ECommerceThankYouPage({ location }) {
  return (
    <Layout location={location}>
      <SEO title={siteTitle} description={siteDescription} />
      <Container>
        <h2>Thank You!</h2>
        <p>Your payment has been received...</p>
        <br />
      </Container>
    </Layout>
  )
}

export default ECommerceThankYouPage
